@import 'extensions';

* {
    box-sizing: border-box;
    &:focus {
        outline: 2px solid $colorBlue;
        outline-offset: 2px;
    }
}

html {
    font-size: 62.5%;
}

body {
    @extend %body-copy;
    background-color: #f0efee;
}

button {
    cursor: pointer;
}

strong,
b {
    font-weight: bold;
}

i,
em {
    font-style: italic;
}

mark {
    background: $colorBlue50;
}

a {
    color: $colorBlack;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

h1 {
    @extend %h1;
}

h2 {
    @extend %h2;
}

h3 {
    @extend %h3;
}

h4 {
    @extend %h4;
}

h5 {
    @extend %h5;
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
    text-transform: none;
}
.no-scroll {
    overflow: hidden;
    height: 100%;
    width: 100%;
}
